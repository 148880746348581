import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const CWDPage = () => {

  return (
    <Layout>
      <SEO title="Custom Web Design" />


    </Layout>
  )
}

export default CWDPage